.react-tel-input .form-control:focus {
    background-color: transparent !important;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25);
}
.react-tel-input .form-control {
    font-size: 16px;
    width:100%;
    background: transparent !important;
    border: -11px solid #CACA;
    border-radius: unset;
    
    outline: none;
    padding: 18.5px 14px 18.5px 60px;
    transition: box-shadow ease .25s,border-color ease .25s;
    color: white;
}
.search .form-group input {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    margin: 5px 0px 3px;
    color: #000 !important;
    padding: 0px 28px 0px;
}