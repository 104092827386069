/* =========== GOOGLE FONTS =========== */


:root {
    --navbar-color: #707070;
    --main-color: #1C1C1C;
    --links-color-mobile: #fff;
}

body.dark-theme {
    --navbar-color: #fff;
    --main-color: #fff;
    --links-color-mobile: #1c1c1c;

}

* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

body {
    font-family: 'Lato';
    background-color: var(--links-color-mobile);
}

.bd-container {
    /* max-width: 968px; */
    width: calc(100% - 3rem);
    margin-left: 6.5rem;
    margin-right: 6.5rem;
}


.l-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 1);
}

/* ========= NAV ========= */



nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__menu {
    display: grid;
    color: #fff;
    /* width: 100%; */
    justify-content: right;
    grid-template-rows: 25px 31px;
}

.main-banner {
    background-image: url(../images/dubai-skyline-with-beautiful-city-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 823px;
    min-width: 100%;
}

.form-contain {
    padding: 31px 27px 21px;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    width: 386px;
    height: 530px;
    overflow: hidden;
    text-align: center;
    margin-bottom: 0;

}

.form-img {
    width: 40%;
}

.form-contain h1 {
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.form-contain p {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 11px;
    text-align: center;
}

.b-btn {
    text-align: right;
}
.top-img {
    order: 2;
  }
  .video-one {
    width: 100%;
    height: 323px;
  }
.form-btn {

    border: 1px solid rgba(0, 101, 142, 1);
    background: rgba(0, 101, 142, 1);
    padding: 10px 29px 10px;
    color: rgba(255, 255, 255, 1);
    font-weight: 400;
    font-size: 14px;
    border-radius: 4px;

}

.form-btn:hover {
    background: rgba(5, 135, 188, 1);
    border: 1px solid rgba(5, 135, 188, 1);

}

.form-btn:focus {
    background: rgba(0, 159, 223, 1);
    border: 1px solid rgba(0, 159, 223, 1);
    outline: unset;

}


.input-icons {
    width: 100%;
    margin-bottom: 10px;
}

.input-icons img {
    position: absolute;
}

.form-group input {
    width: 100%;
    height: 45px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: transparent;
    margin: 5px 0px 3px;
    color: #fff;
    padding: 0px 28px 0px;
}

.icon-pn {
    margin: 75px -324px;
    height: 14px;
}

.icon-pl {
    margin: 73px -324px;
    width: 14px;

}

.icon-pnn {
    margin: 73px -562px;
    height: 14px;
}

.icon-pll {
    margin: 73px -562px;
    height: 14px;
}

.icon-m {
    margin: 20px 7px;
    /* padding: 14px 6px 11px; */
    min-width: 4px;
    width: 14px;
    height: 14px;
}

.container {
    /* position: absolute; */
    top: 53px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin-top: 50px;
    margin-bottom: 30px;
    z-index: 9;
}

section {
    padding: 5rem 8%;
}

.head-main {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: 3px 0px 20px;
}

#head-1 {
    margin-top: -98px;
}

.para-one {
    text-align: justify;
}

.para:after {
    content: '';
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.text h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 51px;
    line-height: 58px;
    margin-bottom: 10px;
    font-weight: 600;
}

span {
    color: #26C1F6;
}

.text p {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 24px;
}

.section-one {
    display: flex;
    justify-content: center;
    margin-bottom: -51px;
}

.main-banner::before {
    content: "";
    position: absolute;
    /* top: 0; */
    left: 0;
    width: 100%;
    /* height: 100%; */
    min-height: 1024px;
    z-index: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.59) 39.28%, rgba(0, 0, 0, 0.86) 80.99%, #000 100%);
}

.person .__BG img {
    width: 320px;
}

.head-main h5 {
    font-size: 48px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}

.head-main button {

    background: var(--material-theme-sys-light-primary, #00658E);
    border: 1px solid var(--material-theme-sys-light-primary, #00658E);
    color: #fff;
    /* width: 296px; */
    height: 43px;
    font-weight: 400;
    font-size: 15px;
    border-radius: 4px;
    vertical-align: middle;
    align-items: center;
    display: grid;
    justify-content: space-evenly;
    padding: 0px 12px 0px;
    line-height: 13px;
    grid-template-columns: 28px 122px;

}

.head-main button:hover {
    background: rgba(5, 135, 188, 1);
    border: 1px solid rgba(5, 135, 188, 1);

}

.head-main button:focus {
    background: rgba(0, 159, 223, 1);
    border: 1px solid rgba(0, 159, 223, 1);
    outline: unset;

}

.get-btn {
    border-radius: 4px;
    background: var(--gradiant, linear-gradient(304deg, #00658E 0%, #008E8C 38.10%, #008E62 100%));
    display: grid;
    padding: 9px 12px 9px 12px;
    font-size: 16px;
    border: unset;
    outline: unset;
    color: #fff;
    grid-template-columns: 24px 79px;
    font-weight: 400;
}

.get-btn:hover {
    background: var(--gradiant, linear-gradient(304deg, #016b96 0%, #049693 38.10%, #06ab78 100%));
    outline: unset;
}

.get-btn:focus {
    background: var(--gradiant, linear-gradient(304deg, #02719c 0%, #05a19f 38.10%, #07ad79 100%));
    outline: unset;
}

.head-main img {
    width: 1.5rem;

}

.head-main p {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
}

/*testimonial*/
.test1-box {
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(45deg, rgba(0, 142, 98, 1), rgba(0, 142, 140, 1)) border-box;
    border: 2px solid transparent;
    border-radius: 35px;
    padding: 20px 20px;
    margin-top: 33px;

}

.test2-box {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 35px;
    padding: 20px 20px;
    margin-top: 33px;
}

.testimonial-1 {
    justify-content: space-evenly;
}

.test-box {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.test-box h5 {
    color: #000000;
    font-size: 22px;
    font-weight: 400;
}

.test-box .pop {
    color: #008E62;
    border: 2px solid #008E62;
    border-radius: 10px;
    width: 70px;
    height: 32px;
    background: transparent;
    transition: 0.4s;
    text-align: center;
}

.test2-box h6 {
    color: #4A4A4A;
    font-weight: 400;
    font-size: 14px;
}

.test1-box h6 {
    color: #4A4A4A;
    font-weight: 400;
    font-size: 14px;
}

.head-main .whatsapp-btn {
    background: #49B451;
    border: 1px solid #49B451;
    color: #fff;
    width: 143px;
    height: 42px;
    font-weight: 400;
    font-size: 15px;
    border-radius: 4px;
    vertical-align: middle;
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding: 3px 11px 3px;
}

.head-main .whatsapp-btn:hover {
    background: #47c451;
    border: 1px solid #47c451;
}

.head-main .whatsapp-btn:focus {
    background: #57cd60;
    border: 1px solid #57cd60;
}

.border-1 {

    border: 1.8px solid #008E8C;
    margin: 3px 0px 13px;

}

.about1-img {
    width: 100%;
}


.home-testimonial {
    background-color: #231834;
}

.home-testimonial-bottom {
    transition: 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    position: relative;
}

.home-testimonial h3 {
    color: var(--orange);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase
}

.home-testimonial h2 {
    color: white;
    font-size: 28px;
    font-weight: 700
}

.testimonial-inner {
    position: relative;
}

.testimonial-pos {
    position: relative;
    top: 24px
}

.testimonial-inner .tour-desc {
    border-radius: 5px;
    padding: 40px;
    top: -32px;
    position: relative;
}

.tour-desc:after {
    border-bottom-color: #256d85;
    left: 3%;
}

.tour-item {
    position: relative;
    text-align: center;
    z-index: 99;
}

.color-grey-3 {
    font-size: 14px;
    color: #6c83a2
}

.testimonial-inner img.tm-people {
    width: 60px;
    height: 60px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    max-width: none
}

.link-name {
    font-size: 14px;
    color: #6c83a2
}

.link-position {
    font-size: 12px;
    color: #6c83a2
}


/*-----Testimonial-------*/

.contact-main:after {
    position: absolute;
    top: -0 !important;
    left: 0;
    content: " ";
    /* background: url(img/testimonial.bg-top.png); */
    background-size: 100% 100px;
    width: 100%;
    height: 100px;
    float: left;
    z-index: 99;
}

#form-contain1 {
    border-radius: 0px;
    width: 39.125rem;
    height: 431px;


}

#form-contain1 h1 {
    font-weight: 400;
    font-size: 32px;
    color: rgba(255, 255, 255, 1);
    text-align: left;

}

#form-contain1 p {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    font-size: 11px;
    text-align: left;
}

#b-btn {
    text-align: left;
}

.contact-main {
    min-height: 375px;
    position: relative;
    background: url(../images/contact-form.jpg);
    padding: 0px;
    /* padding-bottom: 50px; */
    background-position: center;
    background-size: cover;
    justify-content: right;
    display: grid;
}


/*slide-last*/
.content-wrapper {
    height: 100%;
    width: 70%;
    max-width: 100rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 5rem; */
}

h1 {
    margin-bottom: calc(0.7rem + 0.5vmin);
    font-size: calc(2.3rem + 1vmin);
}

.blue-line {
    height: 0.3rem;
    width: 6rem;
    background-color: rgb(79, 143, 226);
    margin-bottom: calc(3rem + 2vmin);
}

.slider-bottom {

    background: rgba(0, 0, 0, 1);
    height: 110px;

}

.wrapper-for-arrows {
    position: relative;
    /* width: 50%; */
    border-radius: 6rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow: hidden;
    display: grid;
    place-items: center;
    border: 1px solid #000;
    background: rgba(14, 14, 14, 1);
    padding: 9px 20px 9px;
    border-left: 17px solid rgba(0, 142, 140, 1);
    border-right: 17px solid rgba(0, 142, 140, 1);
    border-top: 5px solid rgba(0, 142, 140, 1);
    border-bottom: 5px solid rgba(0, 142, 140, 1);
    box-shadow: 0 0 0 8px black, 0 0 0 12px;
}

.sec-main h6 {
    color: rgba(255, 255, 255, 1);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;

}

.sec-main h5 {
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 45px;
    margin-bottom: 0;

}

.sec-main-2 h6 {
    color: rgba(255, 255, 255, 1);
    font-size: 17px;
    font-weight: 400;
}

.sec-main-2 h5 {
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    font-weight: 400;
}

.sec-main-2 button {
    border: 1px solid rgba(73, 180, 81, 1);
    background: rgba(73, 180, 81, 1);
    color: #fff;
    border-radius: 10px;
    padding: 2px 4px 2px;
    font-size: 14px;
    outline: unset;

}

.sec-main-2 button:hover {
    background: #47c451;
    border: 1px solid #47c451;
}

.sec-main-2 button:focus {
    background: #57cd60;
    border: 1px solid #57cd60;
}

.review-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: calc(2rem + 1vmin);
    width: 100%;
}

#imgDiv {
    border-radius: 50%;
    width: calc(6rem + 4vmin);
    height: calc(6rem + 4vmin);
    position: relative;
    box-shadow: 5px -3px rgb(79, 143, 226);
    background-size: cover;
    margin-bottom: calc(0.7rem + 0.5vmin);
}

.content-wrapper {
    justify-content: center;
    display: inline-flex;
    width: 100%;
    top: -64px;
    position: relative;

}

.main-1 {
    display: grid;
    justify-content: space-between;
    /* width: 100%; */
    grid-template-columns: 214px 245px;
    align-items: center;
}

.main-2 {
    display: flex;
}

.strip-sec {
    min-height: 137px;
    align-items: center;
    position: relative;
    background: rgba(0, 0, 0, 1);
    padding: 0px;
    /* padding-bottom: 50px; */
    background-position: center;
    background-size: cover;
    justify-content: center;
    display: grid;
}

.strip-sec button {

    background: var(--gradiant, linear-gradient(304deg, #00658E 0%, #008E8C 38.10%, #008E62 100%));
    height: 46px;
    border: unset;
    padding: 9px 11px 9px;
    width: 228px;
    color: #fff;
    font-weight: 400;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    border-radius: 4px;
    outline: unset;
    margin: 29px 133px 29px;
}

.strip-sec button:hover {
    background: var(--gradiant, linear-gradient(304deg, #026c96 0%, #029795 38.10%, #039667 100%));
    border: 1px solid unset
}

.strip-sec button:focus {
    background: var(--gradiant, linear-gradient(304deg, #0475a1 0%, #049b98 38.10%, #03a16f 100%));
    border: 1px solid rgb(21 178 128);
}



.last-sec {
    text-align: center;
}

.last-sec h1 {
    color: rgba(0, 142, 98, 1);
    font-weight: 400;
    font-size: 57px;
}

.last-sec p {
    font-size: 28px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
}


/*testimonial*/
.testimonial-card,
.testimonial-card * {
    box-sizing: border-box;
}

.testimonial-card {
    flex-shrink: 0;
    width: 358px;
    height: 527px;
    position: relative;
}

.union {
    position: absolute;
    left: 0px;
    top: 92px;
    overflow: visible;
}

.photo-frame {
    width: 173px;
    height: 173px;
    position: absolute;
    left: 93px;
    /* top: 0px; */
    background: #dfe2e5;
    border-radius: 50%;
    border: 6px solid #fff;
}

.ellipse-12 {
    background: #ffffff;
    border-radius: 50%;
    width: 173px;
    height: 173px;
    position: absolute;
    left: 0px;
    top: 0px;
}


.ellipse-21 {
    width: 136px;
    position: absolute;
    left: 10px;
    top: 38px;
}

.ellipse-22 {

    width: 150px;
    position: absolute;
    left: 8px;
    top: 75px;


}

.ellipse-23 {

    width: 153px;
    position: absolute;
    left: 4px;
    top: 56px;

}

.review-card-name {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    width: 298px;
    position: absolute;
    left: 31px;
    top: 173px;
}

.allex-allex {
    color: #000000;
    text-align: center;
    font: var(--material-theme-headline-medium, 400 28px/36px "Lato", sans-serif);
    position: relative;
    width: 298px;
}

.ceo-agency {
    color: #000000;
    text-align: center;
    font: var(--material-theme-body-large, 400 16px/24px "Lato", sans-serif);
    position: relative;
    width: 298px;
}

.frame-37 {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: visible;
}

.frame-38 {
    width: 298px;
    height: 249px;
    position: absolute;
    left: 31px;
    top: 446px;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, -1);
    align-items: flex-start;
    display: inline-flex;
    align-content: flex-end;
    flex-wrap: wrap;
}

.remaining-containing {
    color: #252424;
    position: absolute;
    left: 0px;
    top: 78px;
    width: 294px;
    height: 167px;
    /* transform-origin: 0 0; */
    transform: rotate(0deg) scale(1, -1);
    font-size: 15px;
    line-height: 21px;
    text-align: justify;
}

.remaining-containing :after {
    content: '';
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.test-box .pop {
    color: #008E62;
    border: 2px solid #008E62;
    border-radius: 10px;
    width: 70px;
    height: 32px;
    background: transparent;
    transition: 0.4s;
    text-align: center;
}



.vector {
    position: absolute;
    left: 0.47px;
    top: 266.07px;
    overflow: visible;
}



.row-1 {
    justify-content: center;
}

.column-1 {
    float: left;

    margin: 3px;
}


.row-1:after {
    content: "";
    display: table;
    clear: both;
}


@media screen and (max-width: 600px) {
    .column-1 {
        width: 100%;
    }
}


@media screen and (min-width: 768px) {
    body {
        margin: 0;
    }

    .section {
        padding-top: 7rem;
    }

    .l-header {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav {

        height: calc(3rem + 1.5rem);
    }

    .nav__logo {
        color: var(--navbar-color);
        font-weight: 600;
    }

    .nav__list {
        display: flex;
        align-items: center;
    }

    .nav__item {
        margin-left: 3rem;
        margin-bottom: 0;
    }

    .nav__link {
        color: var(--navbar-color);
        font-weight: 600;
    }


    .__dark-mode {
        margin-left: 2rem;
        cursor: pointer;
    }

    .bx-sun {
        color: var(--main-color);
    }

}

@media(max-width:768px) {
    .text h1 {
        color: rgba(255, 255, 255, 1);
        /* font-family: Poppins; */
        font-size: 34px;
        line-height: 45px;
        margin-bottom: 10px;
        font-weight: 600;
        margin-top: 40px;
    }

    .text p {
        line-height: 27px;
        color: rgba(255, 255, 255, 1);
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 19px;
    }

    .head-main h5 {
        font-size: 32px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
    }

    .head-main button {
        padding: 0px 8px 0px;
        background: rgba(0, 101, 142, 1);
        border: 1px solid rgba(0, 101, 142, 1);
        color: #fff;
        width: 185px;
        height: 41px;
        font-weight: 400;
        font-size: 11px;
        border-radius: 4px;
    }
}

@media(max-width:500px) {
    .head-main {
        justify-content: space-between;
        display: grid;
        align-items: center;
        margin: 3px 0px 20px;
    }
}

@media(max-width:400px) {
    .sec-main-2 button {
        border: 1px solid rgba(73, 180, 81, 1);
        background: rgba(73, 180, 81, 1);
        color: #fff;
        border-radius: 10px;
        padding: 2px 3px 2px;
        font-size: 8px;
        outline: unset;
    }

    .main-1 {
        display: grid;
        justify-content: space-between;
        /* width: 100%; */
        grid-template-columns: 100px 186px;
        align-items: center;
    }

    .img-ceo {
        width: 73px;
    }

    .sec-main h6 {
        color: rgba(255, 255, 255, 1);
        font-size: 11px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .sec-main h5 {
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        font-size: 25px;
        margin-bottom: 0;
    }


    #form-contain1 {
        border-radius: 0px;
        width: 24.525rem;
    }

    nav {
        display: grid;
        justify-content: space-between;
        align-items: center;
    }

    .nav__menu {
        display: grid;
        color: #fff;
        justify-content: center;

    }

    .bd-container {
        /* max-width: 968px; */
        width: calc(100% - 3rem);
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .text h1 {
        margin-top: 154px;
        color: rgba(255, 255, 255, 1);
        /* font-family: Poppins; */
        font-size: 29px;
        line-height: 39px;
        margin-bottom: 10px;
        font-weight: 600;
    }

    .text p {
        color: rgba(255, 255, 255, 1);
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 17px;
        line-height: 24px;
    }

    .head-main h5 {
        font-size: 25px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
        line-height: 38px;
    }

    .last-sec h1 {
        color: rgba(0, 142, 98, 1);
        font-weight: 400;
        font-size: 30px;
    }

    .last-sec p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
    }
}


@media screen and (max-width: 768px) {

    .nav {
        z-index: 100;
        margin-top: 0em;
        justify-content: center;

    }

    .nav__item {
        margin-bottom: 1rem;
    }

    .nav__menu ul li a {
        color: var(--links-color-mobile);
    }

    .nav__link,
    .nav__logo,
    .nav__toggle {
        color: var(--main-color);
        font-weight: 600;
    }

    .nav__link {
        transition: .3s;
    }


}
.float{
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
  font-size:30px;
    box-shadow: 2px 2px 3px #999;
  z-index:100;
}
.float:hover{
    color:#FFF;
}
.my-float{
    margin-top:16px;
}
/* =========== SECTION ============  */

@media screen and (min-width:750px) and (max-width: 1000px) {
    .text h1 {
        /* font-family: Poppins; */
        font-size: 40px;
        line-height: 40px;
    }

    p {
        font-size: 14px;
    }

    .person .form-contain {
        width: 290px;
        /* height: 320px */
    }

    .person .__BG img {
        width: 220px;
    }

}


@media screen and (min-width:250px) and (max-width:750px) {
    .content {
        flex-direction: column;
    }

    .person {
        margin-top: 20px;
    }

    .person .form-contain {
        width: 287px;
        /* height: 320px */
    }

    .person .__BG img {
        width: 220px;
    }



}

@media(max-width:280px) {
    #form-contain1 {
        border-radius: 0px;
        width: 18rem;
    }

    .sec-main-2 h5 {
        color: rgba(255, 255, 255, 1);
        font-size: 10px;
        font-weight: 400;
    }

    .sec-main-2 h6 {
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        font-weight: 400;
    }

    .sec-main-2 button {
        border: 1px solid rgba(73, 180, 81, 1);
        background: rgba(73, 180, 81, 1);
        color: #fff;
        border-radius: 10px;
        padding: 2px 3px 2px;
        font-size: 6px;
        outline: unset;
    }

    .main-1 {
        display: grid;
        justify-content: space-between;
        /* width: 100%; */
        grid-template-columns: 90px 143px;
        align-items: center;
    }

    .sec-main h6 {
        color: rgba(255, 255, 255, 1);
        font-size: 10px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .sec-main h5 {
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 0;
    }
}