.pushdaddy-button-label{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #1e2433;
    background-color: #fff;
    border-radius: 8px;
    padding: 8px 15px;
    white-space: nowrap;
    margin: 1px -144px;
    font-size: 15px;
    line-height: 1.2;
    box-shadow: 0 6px 32px #00122e3d;
    transition: margin .16s linear 0s,opacity 80ms linear 0s;
}